import axios, { type AxiosResponse } from 'axios'
import { useRouter } from 'next/router'
import {
  type BaseSyntheticEvent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'

import { type SanitySignupFormBlock } from '@data/sanity/queries/types/blocks'
import { AnalyticsEventName } from '@lib/analytics'
import { AnalyticsContext } from '@lib/analytics-context'
import { countryNames } from '@lib/country'
import { FacebookEvent, triggerFacebookEvent } from '@lib/facebook-client'
import { type DropdownOption, compareStrings } from '@lib/helpers'
import { LanguageContext } from '@lib/language-context'
import { type ErrorMessages, ParseResults } from '@lib/request'
import { getLinkPageUrl } from '@lib/routes'
import { addEmailToNewsletterList } from '@lib/services'
import { SiteContext } from '@lib/site-context'
import { StringsContext } from '@lib/strings-context'
import { type SignupFormValues } from '@lib/user/types'

import Alert from '@components/alert'
import Button, { ButtonVariant } from '@components/buttons/button'
import Checkbox from '@components/checkbox'
import ComplexPortableText from '@components/complex-portable-text'
import InputDropdown from '@components/input-dropdown/with-form-register'
import InputField from '@components/input-field/with-form-register'
import TelInputField from '@components/tel-input-field'

type SignupResponse =
  | ParseResults
  | {
      error: string
    }

type SignupFormProps = Pick<
  SanitySignupFormBlock,
  'service' | 'klaviyoListID' | 'signupFormStrings'
> & {
  className?: string
}

const SignupForm = ({
  service,
  klaviyoListID,
  signupFormStrings,
  className,
}: SignupFormProps) => {
  const { locale } = useContext(LanguageContext)
  const { settings } = useContext(SiteContext)
  const strings = useContext(StringsContext)
  const { triggerAnalyticsEvent } = useContext(AnalyticsContext)

  const router = useRouter()

  const {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<SignupFormValues>()
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>({})

  const countryOptions = useMemo<DropdownOption[]>(
    () =>
      Object.entries(countryNames[locale])
        .map(([value, title]) => ({ value, title }))
        .sort((country1, country2) =>
          compareStrings(country1.title, country2.title)
        ),
    [locale]
  )
  const password = watch('password')
  const subscribeToNewsletter = watch('subscribeToNewsletter')

  const firstNameRegister = register('firstName', {
    required: signupFormStrings.signupFirstNameMissing,
  })
  const lastNameRegister = register('lastName', {
    required: signupFormStrings.signupLastNameMissing,
  })
  const companyRegister = register('company', {
    required: signupFormStrings.signupCompanyMissing,
  })
  const vatNumberRegister = register('vatNumber', {
    required: signupFormStrings.signupVatNumberMissing,
  })
  const addressRegister = register('address', {
    required: signupFormStrings.signupAddressMissing,
  })
  const postalCodeRegister = register('postalCode', {
    required: signupFormStrings.signupPostalCodeMissing,
  })
  const cityRegister = register('city', {
    required: signupFormStrings.signupCityMissing,
  })
  const countryRegister = register('countryCode', {
    required: signupFormStrings.signupCountryMissing,
  })
  const emailRegister = register('email', {
    required: signupFormStrings.signupEmailMissing,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: strings.emailInvalid,
    },
  })
  register('phone', {
    required: signupFormStrings.signupPhoneMissing,
  })
  const passwordRegister = register('password', {
    required: signupFormStrings.signupPasswordMissing,
  })
  const repeatPasswordRegister = register('repeatPassword', {
    required: signupFormStrings.signupRepeatPasswordMissing,
    validate: (value) => {
      if (password !== value) {
        return signupFormStrings.signupRepeatPasswordInvalid
      }
    },
  })
  const subscribeToNewsletterRegister = register('subscribeToNewsletter')

  const newsletterListId = useMemo(() => {
    if (service === 'klaviyo') {
      return klaviyoListID
    }

    return null
  }, [klaviyoListID, service])

  const handlePhoneChange = useCallback(
    (value: string) => {
      setValue('phone', value.replace(/\(|\)|-|\s/g, ''))
      trigger('phone')
    },
    [setValue, trigger]
  )

  const onSubmit = useCallback(
    async (values: SignupFormValues, event?: BaseSyntheticEvent) => {
      event?.preventDefault()

      setIsLoading(true)
      setIsError(false)
      setErrorMessages({})

      try {
        if (subscribeToNewsletter && !!newsletterListId) {
          await addEmailToNewsletterList(
            locale,
            service,
            newsletterListId,
            values.email,
            `${values.firstName} ${values.lastName}`
          )
          triggerAnalyticsEvent(AnalyticsEventName.NewsletterSignUp)
        }

        // Create user
        const signupResponse = await axios.post<
          SignupResponse,
          AxiosResponse<SignupResponse>,
          SignupFormValues
        >('/api/auth/signup', values, {
          headers: {
            'Content-Type': 'application/json',
            'X-Locale': locale,
          },
        })

        if (
          'fieldErrors' in signupResponse.data &&
          signupResponse.data.fieldErrors
        ) {
          setErrorMessages(signupResponse.data.fieldErrors)
          setIsLoading(false)
          return
        }
      } catch (error) {
        console.log(error)
        setIsLoading(false)
        setIsError(true)
        return
      }

      if (settings?.facebookEvents) {
        await triggerFacebookEvent(FacebookEvent.COMPLETE_REGISTRATION, locale)
      }

      reset()
      setIsLoading(false)

      // Redirect to signup success page
      const url = getLinkPageUrl('signupSuccessPage')
      router.push(url, url, {
        locale,
      })
    },
    [
      locale,
      newsletterListId,
      reset,
      router,
      service,
      settings,
      subscribeToNewsletter,
      triggerAnalyticsEvent,
    ]
  )

  const isDisabled =
    !!errors.firstName ||
    !!errors.lastName ||
    !!errors.company ||
    !!errors.vatNumber ||
    !!errors.address ||
    !!errors.postalCode ||
    !!errors.city ||
    !!errors.countryCode ||
    !!errors.email ||
    !!errors.phone ||
    !!errors.password ||
    !!errors.repeatPassword

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-2 gap-8 mb-8">
        <InputField
          type="text"
          formRegister={firstNameRegister}
          label={signupFormStrings.signupFirstName}
          placeholder={signupFormStrings.signupFirstNamePlaceholder}
          errorMessage={errorMessages?.firstName ?? errors.firstName?.message}
        />
        <InputField
          type="text"
          formRegister={lastNameRegister}
          label={signupFormStrings.signupLastName}
          placeholder={signupFormStrings.signupLastNamePlaceholder}
          errorMessage={errorMessages?.lastName ?? errors.lastName?.message}
        />
      </div>

      <hr className="mt-0 mb-8 bg-divider" />

      <InputField
        type="text"
        formRegister={companyRegister}
        label={signupFormStrings.signupCompany}
        placeholder={signupFormStrings.signupCompanyPlaceholder}
        errorMessage={errorMessages?.company ?? errors.company?.message}
        className="mb-4"
      />
      <InputField
        type="text"
        formRegister={vatNumberRegister}
        label={signupFormStrings.signupVatNumber}
        placeholder={signupFormStrings.signupVatNumberPlaceholder}
        errorMessage={errorMessages?.vatNumber ?? errors.vatNumber?.message}
        className="mb-8"
      />

      <hr className="mt-0 mb-8 bg-divider" />

      <InputField
        type="text"
        formRegister={addressRegister}
        label={signupFormStrings.signupAddress}
        placeholder={signupFormStrings.signupAddressPlaceholder}
        errorMessage={errorMessages?.address ?? errors.address?.message}
        className="mb-4"
      />
      <div className="grid grid-cols-2 gap-8">
        <InputField
          type="text"
          formRegister={postalCodeRegister}
          label={signupFormStrings.signupPostalCode}
          placeholder={signupFormStrings.signupPostalCodePlaceholder}
          errorMessage={errorMessages?.postalCode ?? errors.postalCode?.message}
          className="mb-4"
        />
        <InputField
          type="text"
          formRegister={cityRegister}
          label={signupFormStrings.signupCity}
          placeholder={signupFormStrings.signupCityPlaceholder}
          errorMessage={errorMessages?.city ?? errors.city?.message}
          className="mb-4"
        />
      </div>
      <InputDropdown
        formRegister={countryRegister}
        options={countryOptions}
        label={signupFormStrings.signupCountry}
        placeholder={signupFormStrings.signupCountryPlaceholder}
        defaultValue="DK"
        errorMessage={errorMessages?.country ?? errors.countryCode?.message}
        className="mb-8"
      />

      <hr className="mt-0 mb-8 bg-divider" />

      <div className="grid grid-cols-2 gap-8 mb-8">
        <InputField
          type="email"
          autoComplete="email"
          formRegister={emailRegister}
          label={signupFormStrings.signupEmail}
          placeholder={signupFormStrings.signupEmailPlaceholder}
          errorMessage={errorMessages?.email ?? errors.email?.message}
        />
        <TelInputField
          name="phone"
          label={signupFormStrings.signupPhone}
          placeholder={signupFormStrings.signupPhonePlaceholder}
          errorMessage={errorMessages?.phone ?? errors.phone?.message}
          onChange={handlePhoneChange}
        />
      </div>

      <hr className="mt-0 mb-8 bg-divider" />

      <div className="grid grid-cols-2 gap-8 mb-8">
        <InputField
          type="password"
          autoComplete="off"
          formRegister={passwordRegister}
          label={signupFormStrings.signupPassword}
          placeholder={signupFormStrings.signupPasswordPlaceholder}
          errorMessage={errorMessages?.password ?? errors.password?.message}
        />
        <InputField
          type="password"
          autoComplete="off"
          formRegister={repeatPasswordRegister}
          label={signupFormStrings.signupRepeatPassword}
          placeholder={signupFormStrings.signupRepeatPasswordPlaceholder}
          errorMessage={
            errorMessages?.repeatPassword ?? errors.repeatPassword?.message
          }
        />
      </div>

      <Checkbox formRegister={subscribeToNewsletterRegister} className="mb-4">
        <p className="m-0">{signupFormStrings.newsletterLabel}</p>
      </Checkbox>

      {(isError || errorMessages?.[0]) && (
        <div key="error" className="mt-8">
          <Alert error>
            <ComplexPortableText
              className="rc rc-alert rc-error"
              content={signupFormStrings.signupErrorMessage}
            />
          </Alert>
        </div>
      )}

      <div className="flex justify-center mt-8">
        <Button
          type="submit"
          variant={ButtonVariant.FILLED}
          disabled={isLoading || isDisabled}
          className="min-w-[260px]"
        >
          {isLoading
            ? strings.buttonSubmitting
            : signupFormStrings.signupSubmit}
        </Button>
      </div>
    </form>
  )
}

export default SignupForm
