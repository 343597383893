import cx from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import { sha256 } from 'js-sha256'
import {
  type BaseSyntheticEvent,
  type FormEvent,
  useCallback,
  useContext,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'

import { type SanityContactFormBlock } from '@data/sanity/queries/types/blocks'
import { AnalyticsEventName } from '@lib/analytics'
import { AnalyticsContext } from '@lib/analytics-context'
import { fadeAnimation } from '@lib/animate'
import {
  type ContactFormValues,
  getContactFormCustomFieldNameMap,
} from '@lib/form'
import { FormValueProvider } from '@lib/form-value-context'
import { LanguageContext } from '@lib/language-context'
import { contact } from '@lib/services'
import { StringsContext } from '@lib/strings-context'

import Alert from '@components/alert'
import Button, {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
  getButtonColor,
  getButtonIconAlignment,
  getButtonSize,
  getButtonVariant,
} from '@components/buttons/button'
import Checkbox from '@components/checkbox'
import ComplexPortableText from '@components/complex-portable-text'
import InputField from '@components/input-field/with-form-register'
import SimplePortableText from '@components/simple-portable-text'
import TelInputField from '@components/tel-input-field'
import TextArea from '@components/textarea'

type ContactFormProps = Omit<SanityContactFormBlock, '_key' | '_type'> & {
  className?: string
}

const ContactForm = ({
  service,
  clickUpListId,
  dynamicParameters,
  analytics,
  terms,
  submit,
  successMsg,
  errorMsg,
  buttonStyle,
  className,
}: ContactFormProps) => {
  const { triggerAnalyticsEvent } = useContext(AnalyticsContext)
  const { locale } = useContext(LanguageContext)
  const strings = useContext(StringsContext)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [formValues, setFormValues] = useState<ContactFormValues>()

  const {
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors },
    setValue,
    trigger,
  } = useForm<ContactFormValues>()
  const hasAcceptedTerms = watch('acceptTerms')
  register('phone', {
    required: strings.phoneNumberMissing,
  })
  const firstNameRegister = register('firstName', {
    required: strings.firstNameMissing,
  })
  const lastNameRegister = register('lastName', {
    required: strings.lastNameMissing,
  })
  const emailRegister = register('email', {
    required: strings.emailMissing,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: strings.emailInvalid,
    },
  })
  const companyRegister = register('company')
  const messageRegister = register('message')
  const acceptTermsRegister = register('acceptTerms')

  const resetForm = useCallback(
    (event: FormEvent) => {
      event.preventDefault()

      reset()
      setIsError(false)
      setIsSuccess(false)
      setIsSubmitting(false)
    },
    [reset]
  )

  const onSubmit = useCallback(
    async (
      contactFormValues: ContactFormValues,
      event?: BaseSyntheticEvent
    ) => {
      event?.preventDefault()

      setIsError(false)
      setIsSuccess(false)
      setFormValues(contactFormValues)

      const contactListId = service === 'clickup' ? clickUpListId : undefined

      // Show an error if there is no contact target
      if (!contactListId) {
        setIsError(true)
        return
      }

      setIsSubmitting(true)

      try {
        const customFieldNameMap =
          getContactFormCustomFieldNameMap(dynamicParameters)
        await contact(
          locale,
          service,
          contactListId,
          contactFormValues,
          customFieldNameMap
        )

        triggerAnalyticsEvent(
          analytics?.submitEventName ?? AnalyticsEventName.Contact,
          {
            user_data: {
              sha256_email_address: sha256(contactFormValues.email),
              phone_number: contactFormValues.phone,
              address: {
                first_name: contactFormValues.firstName,
                last_name: contactFormValues.lastName,
              },
              companyName: contactFormValues.company,
            },
          }
        )

        setIsSuccess(true)
      } catch (error) {
        console.log(error)
        setIsError(true)
      }

      setIsSubmitting(false)
    },
    [
      analytics,
      clickUpListId,
      dynamicParameters,
      locale,
      service,
      triggerAnalyticsEvent,
    ]
  )

  if (!service) {
    return null
  }

  const isDisabled = isSubmitting || (!!terms && !hasAcceptedTerms)

  return (
    <FormValueProvider
      formValues={{
        'contact-firstName': formValues?.firstName,
        'contact-lastName': formValues?.lastName,
        'contact-email': formValues?.email,
        'contact-phone': formValues?.phone?.replace(/ /g, ''),
        'contact-company': formValues?.company,
        'contact-message': formValues?.message,
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={cx(className)}>
        <AnimatePresence mode="wait">
          {!isError && !isSuccess && (
            <motion.div
              initial="hide"
              animate="show"
              exit="hide"
              variants={fadeAnimation}
            >
              <div className="flex flex-col sm:flex-row sm:gap-4">
                <InputField
                  type="text"
                  formRegister={firstNameRegister}
                  label={strings.firstName}
                  placeholder={strings.firstNamePlaceholder}
                  errorMessage={errors.firstName?.message}
                  className="sm:w-1/2 mb-4"
                />

                <InputField
                  type="text"
                  formRegister={lastNameRegister}
                  label={strings.lastName}
                  placeholder={strings.lastNamePlaceholder}
                  errorMessage={errors.lastName?.message}
                  className="sm:w-1/2 mb-4"
                />
              </div>

              <div className="flex flex-col sm:flex-row sm:gap-4">
                <InputField
                  type="email"
                  formRegister={emailRegister}
                  label={strings.emailAddress}
                  placeholder={strings.emailAddressPlaceholder}
                  errorMessage={errors.email?.message}
                  className="sm:w-1/2 mb-4"
                />

                <TelInputField
                  name="phone"
                  label={strings.phoneNumber}
                  placeholder={strings.phoneNumberPlaceholder}
                  errorMessage={errors.phone?.message}
                  onChange={(value: string) => {
                    setValue('phone', value)
                    trigger('phone')
                  }}
                  className="sm:w-1/2 mb-4"
                />
              </div>

              <InputField
                type="text"
                formRegister={companyRegister}
                label={strings.company}
                placeholder={strings.companyPlaceholder}
                errorMessage={errors.company?.message}
                className="mb-4"
              />

              <TextArea
                formRegister={messageRegister}
                label={strings.message}
                placeholder={strings.messagePlaceholder}
                errorMessage={errors.message?.message}
                rows={1}
                className="mb-4"
              />

              {terms && (
                <Checkbox formRegister={acceptTermsRegister} className="mb-3">
                  <SimplePortableText
                    className="rc rc-checkbox"
                    content={terms}
                  />
                </Checkbox>
              )}

              <div className="flex justify-center">
                <Button
                  type="submit"
                  variant={
                    buttonStyle?.variant
                      ? getButtonVariant(buttonStyle.variant)
                      : ButtonVariant.FILLED
                  }
                  size={
                    buttonStyle?.size
                      ? getButtonSize(buttonStyle.size)
                      : ButtonSize.NORMAL
                  }
                  color={
                    buttonStyle?.color
                      ? getButtonColor(buttonStyle.color)
                      : ButtonColor.DEFAULT
                  }
                  icon={buttonStyle?.icon}
                  iconAlignment={getButtonIconAlignment(
                    buttonStyle?.iconAlignment
                  )}
                  disabled={isDisabled}
                  className={cx('mt-3', {
                    'w-full': buttonStyle?.isFullWidth,
                  })}
                >
                  {submit}
                </Button>
              </div>
            </motion.div>
          )}

          {isSuccess && (
            <motion.div
              key="success"
              initial="hide"
              animate="show"
              exit="hide"
              variants={fadeAnimation}
            >
              <Alert>
                <ComplexPortableText
                  content={successMsg}
                  className="rc rc-alert"
                />
              </Alert>
            </motion.div>
          )}

          {isError && (
            <motion.div
              key="error"
              initial="hide"
              animate="show"
              exit="hide"
              variants={fadeAnimation}
            >
              <Alert buttonText={strings.buttonTryAgain} onClick={resetForm}>
                <ComplexPortableText
                  content={errorMsg}
                  className="rc rc-alert"
                />
              </Alert>
            </motion.div>
          )}
        </AnimatePresence>
      </form>
    </FormValueProvider>
  )
}

export default ContactForm
