import { useCallback, useContext, useMemo, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import {
  type SanityAccountAddressDetailsStrings,
  type SanityAddressFormStrings,
} from '@data/sanity/queries/types/blocks'
import {
  type AddressFormValues,
  getAddressFormInitialValues,
} from '@lib/address'
import { fadeAnimation } from '@lib/animate'
import { LanguageContext } from '@lib/language-context'
import { UserContext } from '@lib/user/context'

import Button, { ButtonVariant } from '@components/buttons/button'
import AccountAddressItem from './account-address-item'
import AddressForm, { type AddressFormMode } from './address-form'

interface AccountAddressDetailsProps {
  accountAddressDetailsStrings: SanityAccountAddressDetailsStrings
  addressFormStrings: SanityAddressFormStrings
  className?: string
}

const AccountAddressDetails = ({
  accountAddressDetailsStrings,
  addressFormStrings,
  className,
}: AccountAddressDetailsProps) => {
  const { locale } = useContext(LanguageContext)
  const { user } = useContext(UserContext)

  const [formMode, setFormMode] = useState<AddressFormMode>('create')
  const [addressId, setAddressId] = useState('')
  const [showForm, setShowForm] = useState(false)

  const [formDefaultValues, setFormDefaultValues] = useState<AddressFormValues>(
    () => getAddressFormInitialValues(locale)
  )

  const sortedAddresses = useMemo(() => {
    const addresses = user?.addresses ? [...user.addresses] : []
    return addresses.sort(
      (address1, address2) =>
        (address2.isDefault ? 1 : 0) - (address1.isDefault ? 1 : 0)
    )
  }, [user])

  const showCreateForm = useCallback(() => {
    const values = getAddressFormInitialValues(locale)

    setFormMode('create')
    setFormDefaultValues(values)
    setAddressId('')
    setShowForm(true)
  }, [locale])

  const showEditForm = useCallback((id: string, values: AddressFormValues) => {
    setFormMode('edit')
    setFormDefaultValues(values)
    setAddressId(id)
    setShowForm(true)
  }, [])

  const hide = useCallback(() => setShowForm(false), [])

  const token = user?.token

  if (!token) {
    return null
  }

  return (
    <div className={className}>
      <AnimatePresence mode="wait">
        {showForm && (
          <motion.div
            key="form"
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
          >
            <AddressForm
              addressFormStrings={addressFormStrings}
              mode={formMode}
              hide={hide}
              addressId={addressId}
              defaultValues={formDefaultValues}
            />
          </motion.div>
        )}

        {!showForm && (
          <motion.div
            key="list"
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
            className="flex flex-wrap gap-x-24 gap-y-12"
          >
            <div className="max-w-64 w-full">
              <h4 className="mb-4">
                {accountAddressDetailsStrings.accountAddAddressHeading}
              </h4>

              <Button
                variant={ButtonVariant.FILLED}
                onClick={showCreateForm}
                className="min-w-48"
              >
                {accountAddressDetailsStrings.accountAddAddress}
              </Button>
            </div>

            <div className="flex flex-wrap gap-24 gap-y-8">
              {sortedAddresses.map((address) => (
                <AccountAddressItem
                  key={address.id}
                  address={address}
                  accountAddressDetailsStrings={accountAddressDetailsStrings}
                  showEditForm={showEditForm}
                />
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default AccountAddressDetails
