import { type SanityAccountDetailsStrings } from '@data/sanity/queries/types/blocks'
import { getLinkPageUrl } from '@lib/routes'
import { type UserCustomer } from '@lib/user/types'

import ButtonLink, { ButtonVariant } from '@components/buttons/button-link'

interface AccountCustomerDetailsProps {
  accountDetailsStrings: SanityAccountDetailsStrings
  customer?: UserCustomer
}

const AccountCustomerDetails = ({
  accountDetailsStrings,
  customer,
}: AccountCustomerDetailsProps) => {
  if (!customer) {
    return null
  }

  return (
    <div className="space-y-4">
      <h4>{accountDetailsStrings.accountAccountDetailsHeading}</h4>

      <p>
        {`${customer.firstName} ${customer.lastName}`}
        <br />

        {customer.phone}
        <br />

        {customer.email}
      </p>

      <ButtonLink
        variant={ButtonVariant.LINK}
        href={getLinkPageUrl('accountAddressPage')}
      >
        {accountDetailsStrings.accountViewAddresses}
      </ButtonLink>
    </div>
  )
}

export default AccountCustomerDetails
