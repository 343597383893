import { defaultCountryCodes, englishCountryNames } from './country'
import { type Locale } from './language'

export interface AddressFormValues {
  firstName: string
  lastName: string
  company: string
  address1: string
  address2: string
  city: string
  country: string
  zip: string
  phone: string
  isDefault: boolean
}

/**
 * Gets initial values of the address form.
 */
export const getAddressFormInitialValues = (locale: Locale) => {
  const initialValues: AddressFormValues = {
    firstName: '',
    lastName: '',
    company: '',
    address1: '',
    address2: '',
    city: '',
    country: englishCountryNames[defaultCountryCodes[locale]],
    zip: '',
    phone: '',
    isDefault: false,
  }
  return initialValues
}
